/* Base Controls */
media-controller[kind="carousel"] {
  height: 100%;
}

/* Play button */
media-controller[kind="carousel"] > media-control-bar media-play-button {
  width: 3rem;
  height: 3rem;
}

/* Mute button */
media-controller[kind="carousel"] > media-control-bar media-mute-button {
  padding: 0.5rem;
  width: 2.25rem;
  height: 2.25rem;
}
