/* Base Configuration */
.apple-pay-button {
  display: inline-block;
  box-sizing: border-box;

  padding: var(--sf-custom-padding, 0px);
  border-radius: var(--sf-button-radius, 0px);

  width: 100%;
  height: 48px;

  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: buy;
}

.apple-pay-button-black {
  -apple-pay-button-style: black;
}

.apple-pay-button-white {
  -apple-pay-button-style: white;
}

.apple-pay-button-white-with-line {
  -apple-pay-button-style: white-outline;
}
