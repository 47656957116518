/**
* https://www.embla-carousel.com/get-started/react/
* This is global CSS required by the lib, and should apply to ALL carousels we do.
* For anything specific to carousel in a specific component, you should use Tailwind classes.
* Eg. change the carousel from vertical to horizontal, you would use `flex-col` there.
**/

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}
