/* Variant Controls */
@import "./player/basic.css";
@import "./player/carousel.css";
@import "./player/instagram.css";

/* Base Controls */
media-controller {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  cursor: pointer;

  /* Poster Background */
  background-image: var(--media-control-poster-background-image);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  --media-primary-color: #ffffff;
  --media-secondary-color: #0000007a;
  --media-background-color: transparent;
  --media-control-hover-background: #0000007a;
}

media-controller > video,
media-controller > audio {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;

  min-height: 100%;
  max-height: 100%;

  object-fit: contain;
}

.media-controller-preloading {
  z-index: -10;
  position: absolute;
}

/* Custom Slots */
[part~="media-controller-container"] {
  height: 100%;
  width: 100%;

  min-height: 100%;
  min-width: 100%;

  /* Blurred Background */
  background-image: var(--media-control-blurred-background-image);
  background-size: cover;
  background-position: center;
}

[part~="bottom-right"] {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 0.5rem 0.5rem 0;
}

/* Progress bar */
media-control-bar {
  margin: 0 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

media-time-range {
  opacity: 100%;
  pointer-events: none;
  background-color: transparent;
  margin: 0;

  width: 100%;
  flex-grow: 1;

  --media-range-padding-right: 0;
  --media-range-padding-left: 0;
  --media-range-thumb-opacity: 0;
  --media-range-track-color: #ffffff66;
  --media-range-track-background: #ffffff66;
  --media-time-range-buffered-color: #ffffff00;
  --media-control-hover-background: #ffffff66;
  --media-control-background: #ffffff66;
  --media-range-thumb-background: #ffffff66;
}

/* Loader */
@keyframes pulse-loader {
  0%,
  100% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
}

media-time-range[medialoading] {
  --media-range-track-background: #ffffff;

  animation: pulse-loader 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

/* Play button */
media-play-button {
  border-radius: 50%;
  margin: auto;
  opacity: 0;
}

media-play-button[mediapaused] {
  opacity: 1;
}

/* Mute button */
media-mute-button {
  border-radius: 50%;
  display: var(--media-mute-button-display, "inline-flex");

  --media-icon-color: #fffffff0;
  --media-control-hover-background: #ffffff7a;
}
