/* Video Player */
@media (max-width: 1024px) {
  media-controller[kind="instagram"] {
    height: unset;
  }

  media-controller[kind="instagram"] > video {
    min-width: 100vw;
    max-width: 100vw;

    min-height: 100vh;
    max-height: 100vh;
  }
}

/* Custom Slots */
media-controller[kind="instagram"] > [part~="bottom-right"] {
  margin: 0 0.5rem 0.75rem 0;
}

/* Play button */
media-controller[kind="instagram"] > media-control-bar media-play-button {
  width: 4rem;
  height: 4rem;
}

/* Mute button */
media-controller[kind="instagram"] > media-control-bar media-mute-button {
  padding: 0.7rem;
  width: 2.75rem;
  height: 2.75rem;
}
